if (vista == 'editar' && modulo == 'casos') {
    //Obtener el host actual
    function phost()
    {
        if(window.location.hostname == "localhost"){
            host = "/crm/public/";
        }else if(window.location.hostname == "webapp.supremoenlace.com"){
            host = "/";
        }else{
            host = "/";
        }
        return host;
    }
    $(function() {       
        //alert(id);
        var botones = {
            opciones: ".viewOptions"
        };	
        var token = $('[name="_token"]').val();
        //Init Ticket Grid
        $("#documentosListByCaso").jqGrid({
               url: phost() + 'documentos/ajax-listar-by-caso',
               datatype: "json",
               colNames:[	
                '#',		
                'Nombre',
                'Descripci&oacute;n',
                'Archivo',
                'Usuario',
                'Fecha',            
                'Opciones',
                ''			
            ],
               colModel:[
                {name:'id', index:'id', width: 10, align:"center"},
                {name:'nombre', index:'nombre', width: 15, align:"center"},
                {name:'descripcion', index:'descripcion', width: 20, align:"center"},
                {name:'archivo', index:'archivo', width: 25, align:"center"},
                {name:'usuario', index:'usuario', width: 10, align:"center"},
                {name:'fecha', index:'fecha', width: 10, align:"center"},			
                {name:'opciones', index:'opciones', width:20, align:"center"},
                {name:'options', index:'options', hidedlg:true, hidden: true}
               ],
            mtype: "POST",
               postData: {modulo:'casos', documentable_id: caso_info[0].id, _token: token},       
            height: "auto",
            autowidth: true,
            rowList: [20,30,50,100],
            rowNum: 30,
            page: 1,
            loadonce: true,
            pager: jQuery('#pager4'),
            loadtext: '<p>Cargando Documentos...',
            hoverrows: true,
            viewrecords: true,
            refresh: true,
            gridview: true,
            multiselect: false,
            sortname: 'nombre',		
            sortorder: "asc",
            regional:'es',
            onInitGrid: function () {
            $("<div class='alert-info-grid'>No existen registros...</div>").insertAfter($(this).parent());
        },
        loadComplete: function () {
            var $this = $(this), p = $this.jqGrid("getGridParam");
            if (p.reccount === 0) {
                $this.hide();
                $this.parent().siblings(".alert-info-grid").show();
            } else {
                $this.show();
                $this.parent().siblings(".alert-info-grid").hide();
            }
        },   	
            onSelectRow: function(id){
                $(this).find('tr#'+ id).removeClass('ui-state-highlight');
            },
            
            
            
        });
        //inicia opciones modal
        //Boton de Opciones
            $("#documentosListByCaso").on("click", botones.opciones, function(e){
                e.preventDefault();
                e.returnValue=false;
                e.stopPropagation();
                
                var id = $(this).attr("data-id");
                var rowINFO = $("#documentosListByCaso").getRowData(id);
                var option = rowINFO["options"];
                options = option;		   
                $('#modal').find().css({width:'400'});
                 //Init Modal
                $('#modal').find('.modal-title').empty().append('Opciones: #'+ id +'');
                $('#modal').find('.modal-body').empty().append(options);
                $('#modal').find('.modal-footer').empty();
                $('#modal').modal('show');
            });    
            
    
            $(".modal").on("click", ".eliminar", function(e){	
            var id = $(this).attr("data-id");	
            $.ajax({
            url: phost() + 'gastos/ajax-eliminar',
            type: "post",
            headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {id:id, estado:2},
            success: function (response) {
               setTimeout(function () {
                   toastr.success("Gesti&oacute;n eliminada correctamente");
                   $('#modal').modal('hide'); 
                   $("#gestionesListByCaso").setGridParam({
                url: phost() + 'gestiones/ajax-listar-by-caso',
                datatype: "json",
                postData: {
                    titulo: '',
                    descripcion:'',			
                    fecha: '',
                    usuario: '',
                    _token: $('meta[name="csrf-token"]').attr('content')
                }
            }).trigger('reloadGrid');
               },200);
                
            },
            error: function(jqXHR, textStatus, errorThrown) {
               console.log(textStatus, errorThrown);
            }
    
    
                });    
            });
            
            $("#subir_documento").on("click",function(e) {
        e.preventDefault();
        e.returnValue=false;
        e.stopPropagation();
        $('#modalFormularioIndice').find();
                 //Init Modal
                $('#modalFormularioIndice').find('.modal-title').empty().append('Agregar Registro');
                $('#modalFormularioIndice').modal('show'); 
                  setTimeout(function () {
                   $('#formularioIndice').find('#form_caso_select').select2().val(null);
                   $('#formularioIndice').find('input[type="text"]').prop("value", "");
                   $('#formularioIndice').find('#gestiones_descripcion').prop("value", "");
                   $('.guardarBotonIndice').removeAttr('disabled', 'disabled');   
                   $('.datepicker').datepicker({
                    startView: 0,
                    todayBtn: "linked",
                    keyboardNavigation: false,
                    forceParse: false,
                    autoclose: true,
                    format: "dd/mm/yyyy"
                });            
                     }, 500);
            
    });
    
            $(".tabs_casos").on("click", "#tab_documentos", function(e){
            setTimeout(function(){
                redimensionar();
            },500);
        });
    
        function redimensionar(){
                //Al redimensionar ventana
                $(".ui-jqgrid").each(function(){
                    var w = parseInt( $(this).parent().width()) - 6;
                    var tmpId = $(this).attr("id");
                    var gId = tmpId.replace("gbox_","");
                    $("#"+gId).setGridWidth(w);
                });
                $("#pager4").css({width:'100%'});
            }
    
        //Boton de Buscar Casos
            $(botones.buscar).on("click", function(e){
                e.preventDefault();
                e.returnValue=false;
                e.stopPropagation();
                
                buscarGestion();
            });
            
            //Boton de Reiniciar jQgrid
            $(botones.limpiar).on("click", function(e){
                e.preventDefault();
                e.returnValue=false;
                e.stopPropagation();
                
                recargar();
                limpiarCampos();
            });	
    
        //Reload al jQgrid
        var recargar = function(){
            
            //Reload Grid
            $("#gastosListByCaso").setGridParam({
                url: phost() + 'gastos/ajax-listar-by-caso',
                datatype: "json",
                postData: {
                    titulo: '',
                    caso:'',				
                    fecha: '',
                    usuario: '',
                    _token: token
                }
            }).trigger('reloadGrid');
        };	
        
        return{	    
            init: function() {
                tabla();
                eventos();
            },
            recargar: function(){
                //reload jqgrid
                recargar();
            },
            redimensionar: function(){
                //Al redimensionar ventana
                $(".ui-jqgrid").each(function(){
                    var w = parseInt( $(this).parent().width()) - 6;
                    var tmpId = $(this).attr("id");
                    var gId = tmpId.replace("gbox_","");
                    $("#"+gId).setGridWidth(w);
                });
            }
        }; 
    });
    }