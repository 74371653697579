if (vista == 'movimientos' && modulo == 'archivos') {
$(document).ready(function() {
$('body').addClass('mini-navbar');
$('#codigo').focus();
});

$('body').click(function(){
    $('#codigo').focus();
});

$('#codigo').keypress(function(e){  
    if (e.which == 13) {       
    e.preventDefault();
    e.returnValue=false;
    e.stopPropagation();
    $('form#formularioMovimientos').submit();
    }
});
}