if (vista == 'editar' && modulo == 'casos') {
//VUE JS
var formularioCrearIndice = new Vue({
    el: "#indiceForm",
    data: {
        tomo:'',
        desde : '',
        hasta: '',
        descripcion:'',
        comentarios:''
        },
    methods:{
        validandoFormIndice:function() {            
            if(this.tomo.length < 1 || this.desde.length < 1 || this.hasta.length < 1){
                setTimeout(function () {
                    toastr.error("Complete todos los campos requeridos (*)...");
                }, 300);
            }else{
                $('form#formularioIndice').submit();
                $('.guardarBotonIndice').attr('disabled', 'disabled');
            }
        }
    }
});
}