if (vista == 'editar' && modulo == 'contactos') {
    function phost()
    {
        if(window.location.hostname == "localhost"){
            host = "/crm/public/";
        }else if(window.location.hostname == "webapp.supremoenlace.com"){
            host = "/";
        }else{
            host = "/";
        }
        return host;
    }
$('#nombre').keypress(function() {    
var nombre = $("#nombre").val();
if(nombre.length >= 1){
    $("#guardar_contacto").removeAttr('disabled', 'disabled');
}else{
    $("#guardar_contacto").attr('disabled', 'disabled');
}
});
}