if(vista == 'dashboard'){
$(document).ready(function (){

        // Instance the tour
        var tour = new Tour({
            steps: [{

                    element: "#step1",
                    title: "Casos y Gestiones",
                    content: "Aqu&iacute; podrás encontrar todos los casos y gestiones del sistema.",
                    placement: "top",
                    backdrop: true,
                    backdropContainer: '.wrapper',
                    onShown: function (tour){
                        $('body').addClass('tour-open')
                    },
                    onHidden: function (tour){
                        $('body').removeClass('tour-close')
                    }
                },
                {
                    element: "#step2",
                    title: "Datos Reales",
                    content: "Detalles de todos los casos y gestiones",
                    placement: "bottom",
                    backdrop: true,
                    backdropContainer: '.wrapper',
                    onShown: function (tour){
                        $('body').addClass('tour-open')
                    },
                    onHidden: function (tour){
                        $('body').removeClass('tour-close')
                    }
                },
                {
                    element: "#step3",
                    title: "&Uacute;ltimas Gestiones",
                    content: "Aqu&iacute; encontrar&aacute;s las 10 &uacute;ltimas gestiones en el sistema.",
                    placement: "top",
                    backdrop: true,
                    backdropContainer: '.wrapper',
                },
                {
                    element: "#step4",
                    title: "Tareas",
                    content: "Administre todas las tareas asignadas a su cuenta.",
                    placement: "top",
                    backdrop: true,
                    backdropContainer: '.wrapper',
                }
            ]});

        // Initialize the tour
        tour.init();

        $('.startTour').click(function(){
            tour.restart();

            // Start the tour
            // tour.start();
        })

 //Inicia chart gastos
var uno = parseFloat(gastos_by_mes['enero']).toFixed(2);
var dos = parseFloat(gastos_by_mes['febrero']).toFixed(2);
var tres = parseFloat(gastos_by_mes['marzo']).toFixed(2);
var cuatro = parseFloat(gastos_by_mes['abril']).toFixed(2);
var cinco = parseFloat(gastos_by_mes['mayo']).toFixed(2);
var seis = parseFloat(gastos_by_mes['junio']).toFixed(2);
var siente = parseFloat(gastos_by_mes['julio']).toFixed(2);
var ocho = parseFloat(gastos_by_mes['agosto']).toFixed(2);
var nueve = parseFloat(gastos_by_mes['septiembre']).toFixed(2);
var diez = parseFloat(gastos_by_mes['octubre']).toFixed(2);
var once = parseFloat(gastos_by_mes['noviembre']).toFixed(2);
var doce = parseFloat(gastos_by_mes['diciembre']).toFixed(2);

 var lineData = {
                labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                datasets: [
                    {
                        label: "Este Año",
                        backgroundColor: "rgba(26,179,148,0.5)",
                        borderColor: "rgba(26,179,148,0.7)",
                        pointBackgroundColor: "rgba(26,179,148,1)",
                        pointBorderColor: "#fff",
                        data: [uno, dos, tres, cuatro, cinco, seis, siente, ocho, nueve, diez, once, doce]
                    },
                    {
                        label: "Año Pasado",
                        backgroundColor: "rgba(220,220,220,0.5)",
                        borderColor: "rgba(220,220,220,1)",
                        pointBackgroundColor: "rgba(220,220,220,1)",
                        pointBorderColor: "#fff",
                        data: [0]
                    }
                ]
            };

            var lineOptions = {
                responsive: true
            };


            var ctx = document.getElementById("gastoschart").getContext("2d");
            new Chart(ctx, {type: 'line', data: lineData, options:lineOptions});
       

    });
}