if (vista == 'editar' && modulo == 'casos') {
//Obtener el host actual
function phost()
{
	if(window.location.hostname == "localhost"){
		host = "/crm/";
	}else if(window.location.hostname == "webapp.supremoenlace.com"){
		host = "/";
	}else{
		host = "/";
	}
	return host;
}
$(function() {
	
	
	var id = $('#id').val();
    var token = $('[name="_token"]').val();
	//alert(id);
	var botones = {
		opciones: ".viewOptions",		
		buscar: "#searchBtn",
		limpiar: "#clearBtn"
	};	


	//Init Ticket Grid
	$("#gestionesListByCaso").jqGrid({
	   	url: phost() + 'gestiones/ajax-listar-by-caso',
	   	datatype: "json",
	   	colNames:[
			
            'T&iacute;tulo',
            'Descripci&oacute;n',
			'Fecha',	
			'Usuario',			
			'Opciones',
			''
			
		],
	   	colModel:[
            {name:'titulo', index:'titulo', width: 50, align:"left"},
            {name:'descripcion', index:'descripcion', width: 50, align:"left"},			
			{name:'fecha', index:'fecha', width: 30, align:"left"},	
			{name:'usuario', index:'usuario', width: 30, align:"left"},
			{name:'opciones', index:'opciones', width:30, align:"center"},
			{name:'options', index:'options', hidedlg:true, hidden: true}
	   	],
		mtype: "POST",
	   	postData: {caso:id, _token: token},       
		height: "auto",
		autowidth: true,
		rowList: [20,30,50,100],
		rowNum: 30,
		page: 1,
		loadonce: true,
        pager: jQuery('#pager3'),
		loadtext: '<p>Cargando Gestiones...',
		hoverrows: true,
		viewrecords: true,
		refresh: true,
		gridview: true,
		multiselect: false,
	    sortname: 'titulo',		
	    sortorder: "asc",
		regional:'es',
		onInitGrid: function () {
        $("<div class='alert-info-grid'>No existen registros...</div>").insertAfter($(this).parent());
    },
    loadComplete: function () {
        var $this = $(this), p = $this.jqGrid("getGridParam");
        if (p.reccount === 0) {
            $this.hide();
            $this.parent().siblings(".alert-info-grid").show();
        } else {
            $this.show();
            $this.parent().siblings(".alert-info-grid").hide();
        }
    },   	
		onSelectRow: function(id){
			$(this).find('tr#'+ id).removeClass('ui-state-highlight');
		},
		
		
		
	});

    /*$(".tabs_casos").on("click", "#tab_gestiones", function(e){
        setTimeout(function(){
            redimensionar();
        },500);
    });

    function redimensionar(){
			//Al redimensionar ventana
			$(".ui-jqgrid").each(function(){
				var w = parseInt( $(this).parent().width()) - 6;
				var tmpId = $(this).attr("id");
				var gId = tmpId.replace("gbox_","");
				$("#"+gId).setGridWidth(w);
			});
		} */

	//inicia opciones modal
	//Boton de Opciones
		$("#gestionesListByCaso").on("click", botones.opciones, function(e){
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
			
			var id = $(this).attr("data-id");
			var rowINFO = $("#gestionesListByCaso").getRowData(id);
		    var option = rowINFO["options"];
		    options = option;		   
			$('#modal').find().css({width:'400'});
	 	    //Init Modal
		    $('#modal').find('.modal-title').empty().append('Opciones: #'+ rowINFO["titulo"] +'');
		    $('#modal').find('.modal-body').empty().append(options);
		    $('#modal').find('.modal-footer').empty();
		    $('#modal').modal('show');
		});

		$("#exportar_by_casos").on("click", function(e){
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
			
		var titulo  = $('#titulo').val();
		var caso 		= caso_info[0].id;		
		var fecha = $('#fecha').val();
		var usuario 	= $('#usuario').val();
		$.ajax({
		async: true,	
		url: phost() + 'gestiones/ajax-exportar-by-caso',
		type: "post",
		headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		},		
        data: {titulo:titulo, caso:caso, fecha:fecha, usuario:usuario, estado:1},
        success: function (response) {		   
			toastr.success("Exportando...");
			var w = window.open();
        	$(w.document.body).html(response);
		},
        error: function(jqXHR, textStatus, errorThrown) {
           console.log(textStatus, errorThrown);
        }


            });
		});

		$(".modal").on("click", ".cambiar_estado_caso", function(e){	
		var id = $(this).attr("data-id");	
        $.ajax({
        url: phost() + 'gestiones/ajax-eliminar',
		type: "post",
		headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    	},
        data: {id:id, estado:2},
        success: function (response) {
		   setTimeout(function () {
               toastr.success("Gesti&oacute;n eliminada correctamente");
               $('#modal').modal('hide'); 
               $("#gestionesListByCaso").setGridParam({
			url: phost() + 'gestiones/ajax-listar-by-caso',
			datatype: "json",
			postData: {
				titulo: '',
				descripcion:'',			
				fecha: '',
				usuario: '',
				_token: $('meta[name="csrf-token"]').attr('content')
			}
		}).trigger('reloadGrid');
		   },200);
			
		},
        error: function(jqXHR, textStatus, errorThrown) {
           console.log(textStatus, errorThrown);
        }


            });    
		});

		$(".tabs_casos").on("click", "#tab_gestiones", function(e){
        setTimeout(function(){
            redimensionar();
        },500);
    });

    function redimensionar(){
			//Al redimensionar ventana
			$(".ui-jqgrid").each(function(){
				var w = parseInt( $(this).parent().width()) - 6;
				var tmpId = $(this).attr("id");
				var gId = tmpId.replace("gbox_","");
				$("#"+gId).setGridWidth(w);
			});
			$("#pager3").css({width:'100%'});
		}

	//Boton de Buscar Casos
		$(botones.buscar).on("click", function(e){
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
			
			buscarGestion();
		});
		
		//Boton de Reiniciar jQgrid
		$(botones.limpiar).on("click", function(e){
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
			
			recargar();
			limpiarCampos();
		});	

	//Reload al jQgrid
	var recargar = function(){
		
		//Reload Grid
		$("#gestionesListByCaso").setGridParam({
			url: phost() + 'gestiones/ajax-listar-by-caso',
			datatype: "json",
			postData: {
				titulo: '',
				caso:'',				
				fecha: '',
				usuario: '',
				_token: token
			}
		}).trigger('reloadGrid');
	};	
	
	return{	    
		init: function() {
			tabla();
			eventos();
		},
		recargar: function(){
			//reload jqgrid
			recargar();
		},
		redimensionar: function(){
			//Al redimensionar ventana
			$(".ui-jqgrid").each(function(){
				var w = parseInt( $(this).parent().width()) - 6;
				var tmpId = $(this).attr("id");
				var gId = tmpId.replace("gbox_","");
				$("#"+gId).setGridWidth(w);
			});
		}
	};
});
}