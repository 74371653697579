if (vista == 'editar' && modulo == 'archivos') {
    //Obtener el host actual
    function phost()
    {
        if(window.location.hostname == "localhost"){
            host = "/crm/public/";
        }else if(window.location.hostname == "webapp.supremoenlace.com"){
            host = "/";
        }else{
            host = "/";
        }
        return host;
    }
$(function () {
    $("#btnPrint").click(function () {
        var contents = $("#imprimible_div").html();
        var frame1 = $('<iframe />');
        frame1[0].name = "frame1";
        $("body").append(frame1);
        var frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument.document ? frame1[0].contentDocument.document : frame1[0].contentDocument;
        frameDoc.document.open();
        //Create a new HTML document.
        frameDoc.document.write('<html><head><title>QR</title>');
        frameDoc.document.write('<style type="text/css" media="print">');
        frameDoc.document.write('@page');
        frameDoc.document.write('{ size:  auto; margin: 0mm; }');
        frameDoc.document.write('html{ background-color: #FFFFFF; margin: 0px;}');
        frameDoc.document.write('body { border: solid 1px blue ; margin: 10mm 15mm 10mm 15mm; }');
        frameDoc.document.write('h3 { line-height: 0.2!important; font-weight:bold; margin-left:5px!important; font-size: 12px!important; }');
        frameDoc.document.write('span { font-size: 6px!important; }');
        frameDoc.document.write('</style>');
        //Append the external CSS file.
        //frameDoc.document.write('<link href="'+ phost() +'css/bootstrap.css" rel="stylesheet" type="text/css" />');        
        frameDoc.document.write('<link href="'+ phost() +'css/app.css" rel="stylesheet" type="text/css" />');
        frameDoc.document.write('<link href="'+ phost() +'css/vendor.css" rel="stylesheet" type="text/css" />');
        frameDoc.document.write('</head><body>');
        //Append the DIV contents.
        frameDoc.document.write(contents);
        frameDoc.document.write('</body></html>');
        frameDoc.document.close();
        console.log(frameDoc.document);
        setTimeout(function () {
            window.frames["frame1"].focus();
            window.frames["frame1"].print();
            frame1.remove();
        }, 500);
    });
});

$(document).ready(function(){
    $('.dataTables-movimiento').DataTable({
        order: [ 0, "desc" ],
        pageLength: 25,
        responsive: true,
        dom: '<"html5buttons"B>lTfgitp',
        buttons: [
            {       customize: function (win){
                    $(win.document.body).addClass('white-bg');
                    $(win.document.body).css('font-size', '10px');

                    $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
            }
            }
        ],
        createdRow: function( row, data, dataIndex ) {
            console.log(row);            
                $(".dataTables-movimiento tr").eq(1).addClass('highlightRow'); 
            
        }
    });

});

}