if (vista == 'crear' && modulo == 'gestiones') {
//VUE JS
var formularioCrearGestiones = new Vue({
    el: "#gestionesForm",
    data: {        
        casosList: casos_list != undefined ? casos_list : [],
        caso_gestiones:'',
        titulo_gestiones : '',
        descripcion: '',
        validar_campos: true
    },
    mounted: function () {
        var vm = this;  
    $('#form_caso_select').select2().val(null);
    $("#form_caso_select").on('change', function(e) {  
        console.log("porque entras pelotudo?");
        var selected_one = (typeof $(this).select2('data')[0].id) ? undefined : $(this).select2('data')[0].id;
        if(typeof  (selected_one) != undefined){  
        vm.caso_gestiones = $(this).select2('data')[0].id;
        }
    });  
    
    },
    methods: {
       validandoForm:function() {
        var vm = this;
        Vue.nextTick(() =>{          
        if(vm.caso_gestiones == null){
            setTimeout(function () {
                toastr.error("Seleccione un caso por favor (*)...");
                },500);
        return false;  
        }
        if(vm.titulo_gestiones == null){
            setTimeout(function () {
                toastr.error("Complete todos los campos requeridos (*)...");
                },500);
        return false;                                
           }  
        var mi_array = {caso:vm.caso_gestiones, titulo:vm.titulo_gestiones, descripcion:vm.descripcion};
        vm.guardar_gestiones(mi_array);        
        $('.guardarBoton').attr('disabled', 'disabled');   
        });
       },
    guardar_gestiones:function(mi_array){
        $.ajax({
        url: phost() + 'gestiones/ajax-guardar',
		type: "post",
		headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    	},
        data: {mi_array},
        success: function (response) {
		   setTimeout(function () {
               toastr.success("Gesti&oacute;n creada correctamente");
               Push.create("Nueva Gestion", {
                body: nombre_usuario + " acaba de agregar una gestion...",
                icon: phost() + "icono.png",
                timeout: 15000,
                onClick: function() {
                    window.location="http://crm.carrilloley.com/gestiones";
                    this.close();
                }
            });
               $('#modalFormulario').modal('hide'); 
               $("#gestionesList").setGridParam({
			url: phost() + 'gestiones/ajax-listar',
			datatype: "json",
			postData: {
				titulo: '',
				caso:'',				
				fecha: '',
				usuario: '',
				_token: $('meta[name="csrf-token"]').attr('content')
			}
		}).trigger('reloadGrid');
		   },500);
			
		},
        error: function(jqXHR, textStatus, errorThrown) {
           console.log(textStatus, errorThrown);
        }


            });
     //this.caso_gestiones = null;
     this.titulo_gestiones = '';
     this.descripcion = '';
    }
    }    
});

}