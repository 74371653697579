if (modulo == 'solicitudes' && vista == 'editar') {
    function phost()
    {
        if(window.location.hostname == "localhost"){
            host = "/crm/public/";
        }else if(window.location.hostname == "webapp.supremoenlace.com"){
            host = "/";
        }else{
            host = "/";
        }
        return host;
    }
    $("#monto, #monto_gastos").maskMoney();    
function boton_guardar(logout, event) {
swal({
    title: "¿Estas seguro(a) aceptar esta solicitud?",
    text: "No es posible revertir esta acción.",
    icon: "warning",
    allowOutsideClick: false,
    buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Confirmar",
          value: true,
          visible: true,
          className: "btn-success",
          closeModal: true
        }
      },
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      swal("Solicitud procesada correctamente!, serás redireccionado...", {
        icon: "success",
        buttons:false,
        allowOutsideClick: false
      });
      $('.btn-success').attr('disabled', true);
      setTimeout(() => {
      $('[disabled]').removeAttr('disabled');                       
      $('form#formularioSolicitudes').submit();
      }, 2500);
    }
  });
}  
//rechazar
function boton_cancelar(logout, event) {
    swal({
        title: "¿Estas seguro(a) rechazar esta solicitud?",
        text: "No es posible revertir esta acción.",
        icon: "error",
        allowOutsideClick: false,
        buttons: {
            cancel: {
              text: "Cancelar",
              value: false,
              visible: true,
              className: "",
              closeModal: true,
            },
            confirm: {
              text: "Confirmar",
              value: true,
              visible: true,
              className: "btn-success",
              closeModal: true
            }
          },
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          swal("Solicitud procesada correctamente!, serás redireccionado...", {
            icon: "success",
            buttons:false,
            allowOutsideClick: false
          });
          $('.btn-success').attr('disabled', true);
          setTimeout(() => {
          $('[disabled]').removeAttr('disabled'); 
          var solicitud_id = $('#solicitud_id').val();                      
          $.ajax({
            async: true,	
            url: phost() + 'solicitudes/ajax-rechazar',
            type: "post",
            headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },		
            data: {solicitud_id:solicitud_id, estado:33},
            success: function (response) {		   
                toastr.success("Rechazando..."); 
                window.location.reload();           
            },
            error: function(jqXHR, textStatus, errorThrown) {
               console.log(textStatus, errorThrown);
            }
    
    
                });
          }, 2500);
        }
      });
    }
    function boton_guardar_provisional(logout, event) {
      swal({
          title: "¿Estas seguro(a) aceptar esta solicitud?",
          text: "No es posible revertir esta acción.",
          icon: "warning",
          allowOutsideClick: false,
          buttons: {
              cancel: {
                text: "Cancelar",
                value: false,
                visible: true,
                className: "",
                closeModal: true,
              },
              confirm: {
                text: "Confirmar",
                value: true,
                visible: true,
                className: "btn-success",
                closeModal: true
              }
            },
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            swal("Solicitud procesada correctamente!, serás redireccionado...", {
              icon: "success",
              buttons:false,
              allowOutsideClick: false
            });
            $('.btn-success').attr('disabled', true);
            setTimeout(() => {
              $('[disabled]').removeAttr('disabled'); 
              var solicitud_id = $('#solicitud_id').val();                      
              $.ajax({
                async: true,	
                url: phost() + 'solicitudes/ajax-aprobar-provisional',
                type: "post",
                headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },		
                data: {solicitud_id:solicitud_id, estado:34},
                success: function (response) {		   
                    toastr.success("Aprobando..."); 
                    window.location.reload();           
                },
                error: function(jqXHR, textStatus, errorThrown) {
                   console.log(textStatus, errorThrown);
                }
        
        
                    });
            }, 2500);
          }
        });
      }  
}