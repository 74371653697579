if (vista == 'registro' && modulo == 'archivos') {
var registroApp = new Vue({        
    el: "#formularioAcciones",
    data: {
        ver:'',
        ubicacionesList:ubicaciones_array
    },
    mounted: function () {
    $('body').addClass('mini-navbar');    
    if($('#ubicacion_actual').val() != 2000){
      $('.modal_retirar').attr('disabled', true).css("pointer-events","none");
    }else{
      $('.modal_devolver').attr('disabled', true).css("pointer-events","none");
    }
    },
    methods: {
        isShowRetirar: function (){
            setTimeout(() => {
                $('select').select2();
                $('.summernote').summernote({
                    toolbar: [
                      // [groupName, [list of button]]
                      ['style', ['bold', 'italic', 'underline', 'clear']],
                      ['font', ['strikethrough', 'superscript', 'subscript']],
                      ['fontsize', ['fontsize']],
                      ['color', ['color']],
                      ['para', ['ul', 'ol', 'paragraph']],
                      ['height', ['height']]
                    ]
                  });
              }, 300);
            this.ver = 'retirar';
        },
        isShowDevolver: function (){
            setTimeout(() => {
                $('select').select2();
                $('.summernote').summernote({
                    toolbar: [
                      // [groupName, [list of button]]
                      ['style', ['bold', 'italic', 'underline', 'clear']],
                      ['font', ['strikethrough', 'superscript', 'subscript']],
                      ['fontsize', ['fontsize']],
                      ['color', ['color']],
                      ['para', ['ul', 'ol', 'paragraph']],
                      ['height', ['height']]
                    ]
                  });
              }, 300);
            this.ver = 'devolver';
        },
        retirar: function (logout, event) {
            event.preventDefault();
            swal({
                title: "¿Estas seguro(a) retirar este Archivo?",
                text: "Es importante devolver el archivo a la misma ubicación de donde se retiró.",
                icon: "warning",
                allowOutsideClick: false,
                buttons: {
                    cancel: {
                      text: "Cancelar",
                      value: false,
                      visible: true,
                      className: "",
                      closeModal: true,
                    },
                    confirm: {
                      text: "Aceptar",
                      value: true,
                      visible: true,
                      className: "",
                      closeModal: true
                    }
                  },
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                  swal("Archivo retirado correctamente!, serás redireccionado...", {
                    icon: "success",
                    buttons:false,
                    allowOutsideClick: false
                  });
                  $('.btn-success').attr('disabled', true);
                  setTimeout(() => {                    
                  $('form#formularioRetiro').submit();
                  }, 2500);
                }
              });
        },
        devolver: function (logout, event) {
          event.preventDefault();
          swal({
              title: "¿Estas seguro(a) devolver este Archivo?",
              text: "Es importante devolver el archivo a la misma ubicación de donde se retiró.",
              icon: "warning",
              allowOutsideClick: false,
              buttons: {
                  cancel: {
                    text: "Cancelar",
                    value: false,
                    visible: true,
                    className: "",
                    closeModal: true,
                  },
                  confirm: {
                    text: "Aceptar",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                  }
                },
              dangerMode: true,
            })
            .then((willDelete) => {
              if (willDelete) {
                swal("Archivo devuelto correctamente!, serás redireccionado...", {
                  icon: "success",
                  buttons:false,
                  allowOutsideClick: false
                });
                $('.btn-success').attr('disabled', true);
                setTimeout(() => {
                $('form#formularioDevolver').submit();
                }, 2500);
              }
            });
      }
    }
});
}