if (vista == 'editar' && modulo == 'indice') {
//VUE JS
var formularioCrearIndiceDetalle = new Vue({
    el: "#indiceFormDetalle",
    data: {
        tomo: indice_info.tomo != undefined ? indice_info.tomo : '',
        desde : indice_info.desde != undefined ? indice_info.desde : '',
        hasta: indice_info.hasta != undefined ? indice_info.hasta : '',
        descripcion: indice_info.descripcion != undefined ? indice_info.descripcion : '',
        comentarios: indice_info.comentarios != undefined ? indice_info.comentarios : '',
        },
    methods:{
        validandoFormIndiceDetalle:function() {            
            if(this.tomo.length < 1 || this.desde.length < 1 || this.hasta.length < 1){
                setTimeout(function () {
                    toastr.error("Complete todos los campos requeridos (*)...");
                }, 300);
            }else{
                $('form#formularioIndiceDetalle').submit();
                $('.guardarBotonIndiceDetalle').attr('disabled', 'disabled');
            }
        }
    }
});
}