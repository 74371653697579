if (modulo == 'solicitudes' && vista == 'crear') {
    //Obtener el host actual
    function phost()
    {
        if(window.location.hostname == "localhost"){
            host = "/crm/public/";
        }else if(window.location.hostname == "webapp.supremoenlace.com"){
            host = "/";
        }else{
            host = "/";
        }
        return host;
    }
    $(function() {
        
        setTimeout(function () {
            $('.footable').footable();
            }, 500);

        $("#monto, #monto_gastos").maskMoney();
        var id = $('#gastos_id').val();
        var token = $('[name="_token"]').val();
        //alert(id);
        var botones = {
            opciones: ".viewOptions",		
            buscar: "#searchBtn",
            limpiar: "#clearBtn"
        };	
    
    
        //Init Ticket Grid
        $("#solicitudesList").jqGrid({
               url: phost() + 'solicitudes/ajax-listar',
               datatype: "json",
               colNames:[	
                'C&oacute;digo',
                'Tribunal',
                'Tipo',
                'Casos',
                'Monto',
                'Fecha',	
                'Usuario',
                'Responsable',
                'Estado',
                'Opciones',
                ''
                
            ],
               colModel:[
                {name:'codigo', index:'codigo', width: 20, align:"center"},
                {name:'tribunal', index:'tribunal', width: 40, align:"left"},	
                {name:'tipo', index:'tipo', width: 30, align:"left"},                
                {name:'caso', index:'caso', width: 15, align:"center"},                
                {name:'monto', index:'monto', width: 20, align:"left"},
                {name:'fecha', index:'fecha', width: 20, align:"left"},
                {name:'usuario', index:'usuario', width: 15, align:"left"},
                {name:'responsable', index:'responsable', width: 20, align:"left"},
                {name:'estado', index:'estado', width: 20, align:"left"},
                {name:'opciones', index:'opciones', width:30, align:"center"},
                {name:'options', index:'options', hidedlg:true, hidden: true}
               ],
            mtype: "POST",
               postData: {id:id, _token: token},       
            height: "auto",
            autowidth: true,
            rowList: [20,30,50,100],
            rowNum: 30,
            page: 1,
            loadonce: true,
            pager: jQuery('#pager'),
            loadtext: '<p>Cargando Solicitudes...',
            hoverrows: true,
            viewrecords: true,
            refresh: true,
            gridview: true,
            multiselect: false,
            multipleSearch: true,
            sortname: 'id',		
            sortorder: "desc",
            regional:'es',
            onInitGrid: function () {
            $("<div class='alert-info-grid'>No existen registros...</div>").insertAfter($(this).parent());
        },
        loadComplete: function () {
            var $this = $(this), p = $this.jqGrid("getGridParam");
            if (p.reccount === 0) {
                $this.hide();
                $this.parent().siblings(".alert-info-grid").show();
            } else {
                $this.show();
                $this.parent().siblings(".alert-info-grid").hide();
            }
        },   	
            onSelectRow: function(id){
                $(this).find('tr#'+ id).removeClass('ui-state-highlight');
            },
            
            
            
        });

    
        //inicia opciones modal
        //Boton de Opciones
            $("#solicitudesList").on("click", botones.opciones, function(e){
                e.preventDefault();
                e.returnValue=false;
                e.stopPropagation();
                
                var id = $(this).attr("data-id");
                var rowINFO = $("#solicitudesList").getRowData(id);
                var option = rowINFO["options"];
                options = option;		   
                $('#modal').find().css({width:'400'});
                 //Init Modal
                $('#modal').find('.modal-title').empty().append('Opciones: #'+ rowINFO["codigo"] +'');
                $('#modal').find('.modal-body').empty().append(options);
                $('#modal').find('.modal-footer').empty();
                $('#modal').modal('show');
            });
            
            $("#agregar_solicitudes").on("click",function(e) {
                e.preventDefault();
                e.returnValue=false;
                e.stopPropagation();
                $('#modalFormularioSolicitudes').find();
                 //Init Modal
                $('#modalFormularioSolicitudes').find('.modal-title').empty().append('Solicitudes');
                $('#modalFormularioSolicitudes').modal('show'); 
                  setTimeout(function () {
                   $('.summernote').summernote('reset');
                   $('#modalFormularioSolicitudes').find('#form_cliente_select').select2().val(null);
                   $('#modalFormularioSolicitudes').find('#form_caso_select').select2().val(null);
                   $('#modalFormularioSolicitudes').find('#form_caso_select_tipo').select2().val(null);
                   $('#modalFormularioSolicitudes').find('#form_caso_select_tribunal').select2().val(null);
                   $('#modalFormularioSolicitudes').find('#form_caso_select_responsable').select2().val(null);
                   $('#modalFormularioSolicitudes').find('input[type="text"]').prop("value", "");
                   $('#modalFormularioSolicitudes').find('#gastos_descripcion').prop("value", "");
                   $('.guardarBoton').removeAttr('disabled', 'disabled');               
                     }, 500);
            
            });
    
            //Imprimir html
		$("#exportar_solicitudes").on("click", function(e){

			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
        //INICIA VALIDACION
            swal({
                title: "¿Estas seguro(a) de procesar estas solicitudes?",
                text: "No es posible revertir esta acción!.",
                icon: "warning",
                allowOutsideClick: false,
                buttons: {
                    cancel: {
                      text: "Cancelar",
                      value: false,
                      visible: true,
                      className: "",
                      closeModal: true,
                    },
                    confirm: {
                      text: "Confirmar",
                      value: true,
                      visible: true,
                      className: "btn-success",
                      closeModal: true
                    }
                  },
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                  swal("Solicitudes procesadas correctamente!, espere un momento...", {
                    icon: "success",
                    buttons:false,
                    allowOutsideClick: false
                  });
                  
                  setTimeout(() => {
                    var codigo  = $('#codigo').val();
                    var tribunal 		= $('#tribunal').val();
                    var usuario 	= $('#usuario').val();
                    var tipo 		= $('#tipo').val();		
                    var monto  = $('#monto_search').val();
                    var fecha    = $('#fecha').val();
                    var estado 		= $('#estado').val();
            
                    $.ajax({
                    async: true,	
                    url: phost() + 'solicitudes/ajax-imprimir',
                    type: "post",
                    headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },		
                    data: {codigo:codigo, tribunal:tribunal, usuario:usuario, tipo:tipo, monto:monto, fecha:fecha, estado:estado},
                    success: function (response) {		   
                        toastr.success("Exportando HTML...");
                        var w = window.open();
                        $(w.document.body).html(response);
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                       console.log(textStatus, errorThrown);
                    }
            
            
                        });
                  }, 2500);
                }
              });
            
        //FIN VALIDACION		

		});    
           
    
        //Boton de Buscar Gastos
            $(botones.buscar).on("click", function(e){
                e.preventDefault();
                e.returnValue=false;
                e.stopPropagation();
                buscarSolicitudes();
            });
            
            //Boton de Reiniciar jQgrid
            $(botones.limpiar).on("click", function(e){
                e.preventDefault();
                e.returnValue=false;
                e.stopPropagation();
                
                recargar();
                limpiarCampos();
            });	
    
        //Reload al jQgrid
        var recargar = function(){
            
            //Reload Grid
            $("#solicitudesList").setGridParam({
                url: phost() + 'solicitudes/ajax-listar',
                datatype: "json",
                postData: {
                    codigo:'',
                    tribunal: '',
                    tipo: '',
                    casos:'',
                    monto:'',	
                    cliente:'',
                    estado:'',			
                    fecha: '',
                    usuario: '',
                    _token: token
                }
            }).trigger('reloadGrid');
        };	
            //Buscar casos en jQgrid
        var buscarSolicitudes = function(){
            
        var codigo  = $('#codigo').val();
		var tribunal  = $('#tribunal').val();		
		var cliente = $('#cliente').val();	
		var tipo 	= $('#tipo').val();	
		var monto = $('#monto_search').val();
		var fecha = $('#fecha').val();
        var usuario = $('#usuario').val();		
        var estado = $('#estado').val();

		if(codigo != "" || tribunal != "" || cliente != "" || tipo != "" || monto != "" || fecha != "" || usuario != "" || estado != "")
		{
                //Reload Grid
                $("#solicitudesList").setGridParam({
                    url: phost() + 'solicitudes/ajax-listar',
                    datatype: "json",
                    postData: {
                        codigo: codigo,
                        tribunal: tribunal,
                        cliente: cliente,
                        tipo: tipo,
                        monto: monto,
                        fecha: fecha,
                        usuario: usuario,
                        estado: estado,                        									
                        _token: token
                    }
                }).trigger('reloadGrid');
            }
        };
        
        //Limpiar campos de busqueda
        var limpiarCampos = function(){
            console.log("entrando");
            $('#buscarSolicitudesForm').find('input[type="text"]').prop("value", "");
            $('#buscarSolicitudesForm').find('input[type="date"]').prop("value", "");
            $('.chosen-select').val("").trigger('change:updated');
            $('#tipo').val("").trigger('change:updated');
            $('#tribunal').val("").trigger('change:updated');
            $('#usuario').val("").trigger('change:updated');
            $('#estado').val("").trigger('change:updated');
        };
        
        //Funcion Ajax activar/desactivar colaborador
        var toggleGastos = function(parametros){
            $.ajax({
                url: phost() + 'colaboradores/ajax-toggle-colaborador',
                data: $.extend({erptkn: tkn}, parametros),
                type: "POST",
                dataType: "json",
                cache: false,
            }).done(function(json, textStatus, xhr) {
    
                //Check Session
                if( $.isEmptyObject(json.session) == false){
                    window.location = phost() + "login?expired";
                }
                
                if(xhr.status != 200){
                    //mensaje error
                    toastr.error('Hubo un error al tratar de cambiar el estado.');
                }
                
                //mensaje success
                toastr.success(json.mensaje);
                
                //Recargar tabla jqgrid
                recargar();
                
            }).fail(function(xhr, textStatus, errorThrown) {
                //mensaje error
                toastr.error('Hubo un error al tratar de cambiar el estado.');
            });
        };
        
        return{	    
            init: function() {
                tabla();
                eventos();
            },
            recargar: function(){
                //reload jqgrid
                recargar();
            },
            redimensionar: function(){
                //Al redimensionar ventana
                $(".ui-jqgrid").each(function(){
                    var w = parseInt( $(this).parent().width()) - 6;
                    var tmpId = $(this).attr("id");
                    var gId = tmpId.replace("gbox_","");
                    $("#"+gId).setGridWidth(w);
                });
            }
        };
    });

    //Por aprobar
    $(function() {
        
        //Init Ticket Grid
        $("#solicitudes_por_aprobar").jqGrid({
            url: phost() + 'solicitudes/ajax-listar-aprobar',
            datatype: "json",
            colNames:[	
             'C&oacute;digo',
             'Casos',
             'Usuario',
             'Fecha',
             'Diligencia',
             'Monto',	
             'Estado',             
             'Opciones'
             
         ],
            colModel:[
             {name:'codigo', index:'codigo', width: 10, align:"center"},
             {name:'casos', index:'casos', width: 20, align:"left"},	
             {name:'usuario', index:'usuario', width: 10, align:"left"},                
             {name:'fecha', index:'fecha', width: 15, align:"left"},                
             {name:'diligencia', index:'diligencia', width: 40, align:"left"},
             {name:'monto', index:'monto', width: 20, align:"left"},   
             {name:'estado', index:'estado', width: 20, align:"left"},
             {name:'opciones', index:'opciones', width:30, align:"center"}
            ],
         mtype: "POST",
            postData: {_token: $('meta[name="csrf-token"]').attr('content')},       
         height: "auto",
         autowidth: true,
         rowList: [20,30,50,100],
         rowNum: 30,
         page: 1,
         loadonce: true,
         pager: jQuery('#pager'),
         loadtext: '<p>Cargando Solicitudes por aprobar...',
         hoverrows: true,
         viewrecords: true,
         refresh: true,
         gridview: true,
         multiselect: false,
         multipleSearch: true,
         sortname: 'id',		
         sortorder: "desc",
         regional:'es',
         onInitGrid: function () {
         $("<div class='alert-info-grid'>No existen registros...</div>").insertAfter($(this).parent());
     },
     loadComplete: function () {
         var $this = $(this), p = $this.jqGrid("getGridParam");
         if (p.reccount === 0) {
             $this.hide();
             $this.parent().siblings(".alert-info-grid").show();
         } else {
             $this.show();
             $this.parent().siblings(".alert-info-grid").hide();
         }
     },   	
         onSelectRow: function(id){
             $(this).find('tr#'+ id).removeClass('ui-state-highlight');
         },
         
         
         
     });

     $("#solicitudes_por_aprobar").on("click", ".aprobar_solicitud", function(e){	
    var id = $(this).attr("data-id");
    $.ajax({
    url: phost() + 'solicitudes/ajax-aprobar-solicitud',
    type: "post",
    headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    data: {id:id, estado:'aprobado'},
    success: function (response) {
       setTimeout(function () {
           toastr.success("Solicitud procesada correctamente");
           $('#modal').modal('hide'); 
           $("#solicitudes_por_aprobar").setGridParam({
            url: phost() + 'solicitudes/ajax-listar-aprobar',
                        datatype: "json",
                        colNames:[	
                         'C&oacute;digo',
                         'Casos',
                         'Usuario',
                         'Fecha',
                         'Diligencia',
                         'Monto',	
                         'Estado',             
                         'Opciones'
                         
                     ]
            }).trigger('reloadGrid');
       },200);
        
    },
    error: function(jqXHR, textStatus, errorThrown) {
       console.log(textStatus, errorThrown);
    }


        });    
    });

    $("#solicitudes_por_aprobar").on("click", ".rechazar_solicitud", function(e){	
        var id = $(this).attr("data-id");	
        $.ajax({
        url: phost() + 'solicitudes/ajax-rechazar-solicitud',
        type: "post",
        headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {id:id, estado:'rechazado'},
        success: function (response) {
           setTimeout(function () {
               toastr.success("Solicitud procesada correctamente");
               $("#solicitudes_por_aprobar").setGridParam({
                url: phost() + 'solicitudes/ajax-listar-aprobar',
                            datatype: "json",
                            colNames:[	
                             'C&oacute;digo',
                             'Casos',
                             'Usuario',
                             'Fecha',
                             'Diligencia',
                             'Monto',	
                             'Estado',             
                             'Opciones'
                             
                         ]
                }).trigger('reloadGrid');
           },200);
            
        },
        error: function(jqXHR, textStatus, errorThrown) {
           console.log(textStatus, errorThrown);
        }


            });    
        });

    });
    
    }