if (modulo == 'solicitudes' && vista == 'crear') {
    //VUE JS    
    var formularioCrearSolicitudes = new Vue({
        el: "#solicitudesForm",
        data: {
            clientesList: clientes_list != undefined ? clientes_list : [],
            casosList: [],
            caso:[],
            isDisabled:true,
            tipoList: tipo_list != undefined ? tipo_list : [],
            tribunalList: despachoArray != undefined ? despachoArray : [],
            tipo:'',
            tribunal:'',
            provisional:'',
            inputs: ['1'],
            cliente:[],
            caso_gastos:'',
            monto:'',
            descripcion: '',
            validar_campos: true,
            clienteSeleccionado: true,
            usuariosList: usuariosArray != undefined ? usuariosArray : [],
            responsable: ''
        },
        mounted: function () {
            var vm = this;
        $('#form_caso_select:last-child').select2().val(null);
        $('#form_caso_select_tipo:last-child').select2().val(null);
        $('#form_caso_select_tribunal:last-child').select2().val(null);
        $("#form_caso_select_tipo").on('change', function(e) {
            var selected_one = (typeof $(this).select2('data')[0].id) ? undefined : $(this).select2('data')[0].id;
            if(typeof  (selected_one) != undefined){  
            vm.tipo = $(this).select2('data')[0].id;
            }
        }); 
       
        $("#form_caso_select_tribunal").on('change', function(e) {
            var selected_one = (typeof $(this).select2('data')[0].id) ? undefined : $(this).select2('data')[0].id;
            if(typeof  (selected_one) != undefined){  
            vm.tribunal = $(this).select2('data')[0].id;
            }
        }); 
        $("#form_caso_select_responsable").on('change', function(e) {
            var selected_one = (typeof $(this).select2('data')[0].id) ? undefined : $(this).select2('data')[0].id;
            if(typeof  (selected_one) != undefined){  
            vm.responsable = $(this).select2('data')[0].id;
            }
        }); 
        },
        methods: {
            customLabel (option) {
                return `${option.nombre}`
              },
            customLabelCasos (option){
                return `${option.nombre}`
            },
            onSelect (option) {            
                var result = $.grep(casos_list, function(e){ return e.cliente == option.id; });
                this.casosList = this.casosList.concat(result);                
                this.isDisabled = false;                
              },            
           validandoForm:function() {
            var vm = this;
            Vue.nextTick(() =>{           
            if(vm.tipo == null || vm.tipo == ''){
                setTimeout(function () {
                    toastr.error("Seleccione Tipo (*)...");
                    },500);
            return false;                                
            }
            if(vm.caso == null || vm.caso == ''){
                setTimeout(function () {
                    toastr.error("Seleccione un Caso por favor (*)...");
                    },500);
            return false;  
            }
            if(vm.tribunal == null || vm.tribunal == ''){
                setTimeout(function () {
                    toastr.error("Seleccione un Tribunal (*)...");
                    },500);
            return false;                                
            }
            if($("#monto").val() == null || $("#monto").val() == ''){
                setTimeout(function () {
                    toastr.error("Ingrese un Monto  (*)...");
                    },500);
            return false;                                
            }  
            if(vm.responsable == null || vm.responsable == ''){
                setTimeout(function () {
                    toastr.error("Seleccione un Responsable  (*)...");
                    },500);
            return false;                                
            }   
            if(vm.descripcion == null || vm.descripcion == ''){
                setTimeout(function () {
                    toastr.error("Ingrese una diligencia (*)...");
                }, 500)
            return false;    
            }
            
            var mi_array = {tipo:vm.tipo, caso:vm.caso, provisional:vm.provisional, tribunal:vm.tribunal, monto:$("#monto").val(), responsable:vm.responsable, descripcion:vm.descripcion};
            vm.guardar_solicitud(mi_array);        
            $('.guardarBoton').attr('disabled', 'disabled'); 

            });
           },
        guardar_solicitud:function(mi_array){
            $.ajax({
            url: phost() + 'solicitudes/ajax-guardar',
            type: "post",
            headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            data: {mi_array},
            success: function (response) {
               setTimeout(function () {
                   toastr.success("Solicitud creada correctamente");
                   $('#modalFormularioSolicitudes').modal('hide'); 
                   $("#solicitudesList").setGridParam({
                url: phost() + 'solicitudes/ajax-listar',
                datatype: "json",
                postData: {
                    codigo: '',
                    tipo:'',
                    diligencia: '',
                    tribunal:'',	
                    caso:'',
                    monto:'',			
                    fecha: '',                
                    usuario: '',
                    responsable:'',
                    estado:'',
                    _token: $('meta[name="csrf-token"]').attr('content')
                }
            }).trigger('reloadGrid');
            //notification
            Push.create("Nueva Solicitud", {
                body: nombre_usuario + " acaba de crear una solicitud...",
                icon: phost() + "icono.png",
                timeout: 15000,
                onClick: function() {
                    window.location="http://crm.carrilloley.com/solicitudes";
                    this.close();
                }
            });
               },500);
                
            },
            error: function(jqXHR, textStatus, errorThrown) {
               console.log(textStatus, errorThrown);
            }
    
    
                });
         this.tipo = '',
         this.caso = '',
         this.tribunal = '';
         this.provisional = '',
         $('#monto').val('');
         this.responsable = '';
         this.descripcion = '';
        }       
        },
        components: {
            Multiselect: window.VueMultiselect.default
          }    
    }).$mount('#app');

 

    }