if (modulo == 'casos') {
//Obtener el host actual
function phost()
{
	if(window.location.hostname == "localhost"){
		host = "/crm/public/";
	}else if(window.location.hostname == "webapp.supremoenlace.com"){
		host = "/";
	}else{
		host = "/";
	}
	return host;
}
$(function() {
	
	var id = $('#caso_id').val();
    var token = $('[name="_token"]').val();
	//alert(id);
	var botones = {
		opciones: ".viewOptions",		
		buscar: "#searchBtn",
		limpiar: "#clearBtn"
	};	


	//Init Ticket Grid
	$("#casosList").jqGrid({
	   	url: phost() + 'casos/ajax-listar',
	   	datatype: "json",
	   	colNames:[
			
			'# Ref.',
			'Nombre',
			'Cliente',
			'Tipo',
			'Despacho',	
			'Expediente',					
			'&Uacute;ltima Gesti&oacute;n',
			'Estado',
			'Opciones',
			''
			
		],
	   	colModel:[
			//{name:'id', index:'id', width:10, align:"center", hidden:true},
			{name:'caso_id', index:'caso_id', width:10, align:"center"},
	   		{name:'nombre', index:'cliente', width: 50, align:"left"},
			{name:'cliente', index:'cliente', width:30, align:"left"},
			{name:'tipo', index:'tipo', width: 30, align:"left"},
			{name:'despacho', index:'despacho', width: 30, align:"left"},	
			{name:'expediente', index:'expediente', width: 30, align:"left"},						
			{name:'ultima_gestion', index:'ultima_gestion', width:30, align:"left"},
			{name:'estado', index:'estado', width:20, align:"center"},
			{name:'opciones', index:'opciones', width:30, align:"center"},
			{name:'options', index:'options', hidedlg:true, hidden: true}
	   	],
		mtype: "POST",
	   	postData: {id:id, _token: token},       
		height: "auto",
		autowidth: true,
		rowList: [20,30,50,100],
		rowNum: 30,
		page: 1,
		loadonce: true,
        pager: jQuery('#pager'),
		loadtext: '<p>Cargando Casos...',
		hoverrows: true,
		viewrecords: true,
		refresh: true,
		gridview: true,
		multiselect: false,
	    sortname: 'caso_id',		
	    sortorder: "asc",
		regional:'es',
		onInitGrid: function () {
        $("<div class='alert-info-grid'>No existen registros...</div>").insertAfter($(this).parent());
    },
    loadComplete: function () {
        var $this = $(this), p = $this.jqGrid("getGridParam");
        if (p.reccount === 0) {
            $this.hide();
            $this.parent().siblings(".alert-info-grid").show();
        } else {
            $this.show();
            $this.parent().siblings(".alert-info-grid").hide();
        }
    },   	
		onSelectRow: function(id){
			$(this).find('tr#'+ id).removeClass('ui-state-highlight');
		},
		
		
		
	});

	//inicia opciones modal
	//Boton de Opciones
		$("#casosList").on("click", botones.opciones, function(e){
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
			
			var id = $(this).attr("data-id");
			var rowINFO = $("#casosList").getRowData(id);
		    var option = rowINFO["options"];
		    options = option;		   
			$('#modal').find().css({width:'400'});
	 	    //Init Modal
		    $('#modal').find('.modal-title').empty().append('Opciones: #'+ rowINFO["caso_id"] +'');
		    $('#modal').find('.modal-body').empty().append(options);
		    $('#modal').find('.modal-footer').empty();
		    $('#modal').modal('show');
		});

		//Exportar Excel
		$("#exportar").on("click", function(e){
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
			
		var referencia  = $('#caso_id').val();
		var nombre 		= $('#nombre').val();
		var cliente 	= $('#cliente').val();
		var ultima_gestion = $('#ultima_gestion').val();
		var tipo 		= $('#tipo').val();		
		var expediente  = $('#expediente').val();
		var despacho    = $('#despacho').val();
		var estado 		= $('#estado').val();

		$.ajax({
		async: true,	
		url: phost() + 'casos/ajax-exportar',
		type: "post",
		headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		},		
        data: {id:referencia, nombre:nombre, cliente:cliente, ultima_gestion:ultima_gestion, tipo:tipo, expediente:expediente, despacho, estado:estado},
        success: function (response) {		   
			toastr.success("Exportando...");
			setTimeout(function () {	
			var a = document.createElement("a");
        a.href = response.file; 
        a.download = response.name;
        document.body.appendChild(a);
        a.click();
		a.remove();
		}, 800);
		},
        error: function(jqXHR, textStatus, errorThrown) {
           console.log(textStatus, errorThrown);
        }


            });
		});

		//Imprimir html
		$("#imprimir").on("click", function(e){
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
			
		var referencia  = $('#caso_id').val();
		var nombre 		= $('#nombre').val();
		var cliente 	= $('#cliente').val();
		var ultima_gestion = $('#ultima_gestion').val();
		var tipo 		= $('#tipo').val();		
		var expediente  = $('#expediente').val();
		var despacho    = $('#despacho').val();
		var estado 		= $('#estado').val();

		$.ajax({
		async: true,	
		url: phost() + 'casos/ajax-imprimir',
		type: "post",
		headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		},		
        data: {id:referencia, nombre:nombre, cliente:cliente, ultima_gestion:ultima_gestion, tipo:tipo, expediente:expediente, despacho, estado:estado},
        success: function (response) {		   
			toastr.success("Exportando HTML...");
			var w = window.open();
        	$(w.document.body).html(response);
		},
        error: function(jqXHR, textStatus, errorThrown) {
           console.log(textStatus, errorThrown);
        }


            });
		});

		//UPLOAD FILES modal
		$(".modal").on("click", ".subir_documento", function(e){			
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
			var id = $(this).attr("data-id");
			var rowINFO = $("#casosList").getRowData(id);		
			$('#modal').modal('hide');
			setTimeout(function () {
			$('#modalDocumento').find().css({width:'400'});
	 	    //Init Modal
			$('#modalDocumento').find('.modal-title').empty().append('Caso: #'+ rowINFO["caso_id"] +'');
			$('#modalDocumento').find('#caso_id').empty().val(rowINFO["caso_id"]);
		    $('#modalDocumento').find('.modal-body');
		    $('#modalDocumento').find('.modal-footer');
			$('#modalDocumento').modal('show');
			}, 500);
		});

		//UPLOAD FILES modal
		$("#subir_documento_caso").on("click", function(e){			
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();			
			$('#modal').modal('hide');
			setTimeout(function () {
			$('#modalDocumento').find().css({width:'400'});
	 	    //Init Modal
			$('#modalDocumento').find('.modal-title').empty().append('Caso: #'+ caso_info[0].id +'');
			$('#modalDocumento').find('#caso_id').empty().val(caso_info[0].id);
		    $('#modalDocumento').find('.modal-body');
		    $('#modalDocumento').find('.modal-footer');
			$('#modalDocumento').modal('show');
			}, 500);
		});

		$("#modalDocumento").on("click", "#submit_documento", function(e){
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation(); 
			var file = $('#file').val();
			if(file.length >= 1) {
				$('form#uploadFiles').submit();
				$('.submit_documento').attr('disabled', 'disabled');
				setTimeout(function () {
					toastr.success("Documentos Guardados Correctamente");
					$('#modal').modal('hide'); 
				}, 300)
			}
			else{
				setTimeout(function () {
                    toastr.error("Error: Seleccione un documento por favor (*)...");
                }, 300);
			}
		});

		//confirmar modal
		$(".modal").on("click", ".cambiar_estado", function(e){
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
			var id = $(this).attr("data-id");
			var rowINFO = $("#casosList").getRowData(id);
			var options2 = '<center><h4>¿Desea cerrar este caso?</h4><br />';
				options2 += '<a href="#" data-id="'+ id +'" class="btn btn-w-m btn-danger confirmar_borrado">Confirmar</a>';	
				options2 += '</center>';
			$('#modal').modal('hide');
			setTimeout(function () {
			$('#modal').find().css({width:'400'});
	 	    //Init Modal
		    $('#modal').find('.modal-title').empty().append('Opciones: #'+ rowINFO["caso_id"] +'');
		    $('#modal').find('.modal-body').empty().append(options2);
		    $('#modal').find('.modal-footer').empty();
			$('#modal').modal('show');
			}, 500);
		});

		//cambiar estado confirmar
		$(".modal").on("click", ".confirmar_borrado", function(e){			
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
			var id = $(this).attr("data-id");
			$.ajax({
        url: phost() + 'casos/ajax-eliminar',
		type: "post",
		headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    	},
        data: {id:id, estado:2},
        success: function (response) {
		   setTimeout(function () {
               toastr.success("Caso cerrado correctamente");
               $('#modal').modal('hide'); 
               $("#casosList").setGridParam({
			url: phost() + 'casos/ajax-listar',
			datatype: "json",
			postData: {
				referencia: '',
				nombre:'',
				cliente: '',
				ultima_gestion: '',
				tipo: '',
				despacho: '',
				expediente: '',				
				estado: '',
				_token: $('meta[name="csrf-token"]').attr('content')
			}
		}).trigger('reloadGrid');
		   },200);
			
		},
        error: function(jqXHR, textStatus, errorThrown) {
           console.log(textStatus, errorThrown);
        }


            }); 
		});

	//Boton de Buscar Casos
		$(botones.buscar).on("click", function(e){
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
			
			buscarCaso();
		});

		$('#caso_id, #nombre, #cliente, #ultima_gestion, #expediente').keypress(function(e){
			if (e.which == 13) {        
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
			
			buscarCaso();
			}
		});
		
		//Boton de Reiniciar jQgrid
		$(botones.limpiar).on("click", function(e){
			e.preventDefault();
			e.returnValue=false;
			e.stopPropagation();
			
			recargar();
			limpiarCampos();
		});	

	//Reload al jQgrid
	var recargar = function(){
		
		//Reload Grid
		$("#casosList").setGridParam({
			url: phost() + 'casos/ajax-listar',
			datatype: "json",
			postData: {
				referencia: '',
				nombre:'',
				cliente: '',
				ultima_gestion: '',
				tipo: '',
				despacho: '',
				expediente: '',				
				estado: '',
				_token: token
			}
		}).trigger('reloadGrid');
	};	
		//Buscar casos en jQgrid
	var buscarCaso = function(){
		
		var referencia  = $('#caso_id').val();
		var nombre 		= $('#nombre').val();
		var cliente 	= $('#cliente').val();
		var ultima_gestion = $('#ultima_gestion').val();
		var tipo 		= $('#tipo').val();		
		var expediente  = $('#expediente').val();
		var despacho    = $('#despacho').val();
		var estado 		= $('#estado').val();

		if(referencia != "" || nombre != "" || cliente != "" || ultima_gestion != "" || tipo != "" || expediente != "" || despacho != "" || estado  != "")
		{
			//Reload Grid
			$("#casosList").setGridParam({
				url: phost() + 'casos/ajax-listar',
				datatype: "json",
				postData: {
					referencia: referencia,
					nombre: nombre,
					cliente: cliente,
					ultima_gestion: ultima_gestion,
					tipo: tipo,
					despacho: despacho,
					expediente: expediente,					
					estado: estado,					
					_token: token
				}
			}).trigger('reloadGrid');
		}
	};
	
	//Limpiar campos de busqueda
	var limpiarCampos = function(){
		$('#buscarCasosForm').find('input[type="text"]').prop("value", "");
		$('#buscarCasosForm').find('input[type="date"]').prop("value", "");
		$('.chosen-select').val("").trigger('change:updated');
	};
	
	//Funcion Ajax activar/desactivar colaborador
	var toggleCasos = function(parametros){
		$.ajax({
			url: phost() + 'colaboradores/ajax-toggle-colaborador',
			data: $.extend({erptkn: tkn}, parametros),
			type: "POST",
			dataType: "json",
			cache: false,
		}).done(function(json, textStatus, xhr) {

			//Check Session
			if( $.isEmptyObject(json.session) == false){
				window.location = phost() + "login?expired";
			}
			
			if(xhr.status != 200){
				//mensaje error
				toastr.error('Hubo un error al tratar de cambiar el estado.');
			}
			
			//mensaje success
			toastr.success(json.mensaje);
			
			//Recargar tabla jqgrid
			recargar();
			
		}).fail(function(xhr, textStatus, errorThrown) {
			//mensaje error
			toastr.error('Hubo un error al tratar de cambiar el estado.');
		});
	};
	
	return{	    
		init: function() {
			tabla();
			eventos();
		},
		recargar: function(){
			//reload jqgrid
			recargar();
		},
		redimensionar: function(){
			//Al redimensionar ventana
			$(".ui-jqgrid").each(function(){
				var w = parseInt( $(this).parent().width()) - 6;
				var tmpId = $(this).attr("id");
				var gId = tmpId.replace("gbox_","");
				$("#"+gId).setGridWidth(w);
			});
		}
	};
});
}